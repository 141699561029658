import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import { useForm } from "react-hook-form"
import { Row, StyledInput, StyledTextArea } from "../../theme/styles"
import { useDispatch } from "react-redux"
import * as types from "../../store/reducers/types"
import ModalContainer from "../../theme/modalContainer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const StyledHeader = styled.span`
  font-weight: 500;
  font-size: 20px;
  padding-top: 5px;
  padding-bottom: 2px;
`

const EditBackDrop = styled.div`
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  color: #fff;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
`

const ModalWarpper = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  padding: 0 10px 0 10px;
`

const SubmitButton = styled.button`
  background: #5aac44;
  box-shadow: none;
  border: none;
  color: #fff;
  padding: 6px 12px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 20px;
  border-radius: 5px;
  outline: none;
  transition: 0.5s ease;
  &:hover {
    background: #009c63;
  }
`

const IconHolder = styled.span`
  align-self: flex-end;
  cursor: pointer;
  color: red;
  font-size: 22px;
`



const NewTaskModal = props => {
  const modalRoot = document.getElementById("modal-root")
  const { register, handleSubmit, errors } = useForm()
  const [fadeType, setFadeType] = useState(null)
  const dispatch = useDispatch()
  const onSubmit = data => {
    const payload = {
      ...data,
      columndId: props.columnId,
    }
    dispatch({ type: types.CREATE_TASK, payload })
    setFadeType("out")
  }
  useEffect(() => {
    window.addEventListener("keydown", onEscKeyDown, false)
    setFadeType("in")
  }, [])

  const onEscKeyDown = e => {
    if (e.key !== "Escape") return
    setFadeType("out")
  }

  const transitionEnd = e => {
    if (e.propertyName !== "opacity" || fadeType === "in") return
    if (fadeType === "out") {
      props.onClick()
    }
  }

  const closeModal = () => {
    setFadeType("out")
  }

  return ReactDOM.createPortal(
    <EditBackDrop>
      <ModalWarpper>
        <ModalContainer
          id={"modal"}
          className={`wrapper ${props.class}`}
          role="dialog"
          onTransitionEnd={transitionEnd}
          fadeType={fadeType}
        >
          <Row>
            <StyledHeader>Create A New Card</StyledHeader>
            <IconHolder onClick={closeModal}>
              <FontAwesomeIcon icon={faTimes} />
            </IconHolder>
          </Row>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledInput
              name="title"
              type="text"
              placeholder="Title"
              ref={register}
            />

            <StyledTextArea
              name="description"
              type="text"
              placeholder="Description"
              ref={register}
              cols="30"
              rows="5"
            />
            {errors.exampleRequired && <span>This field is required</span>}
            <SubmitButton type="submit">Save</SubmitButton>
          </form>
        </ModalContainer>
      </ModalWarpper>
    </EditBackDrop>,
    modalRoot
  )
}

export default NewTaskModal

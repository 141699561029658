import styled from "styled-components";

const ModalContainer = styled.div`
  background: #ebecf0;
  padding: 20px;
  margin: auto;
  margin-top: 40px;
  border-radius: 5px;
  color: #1c2932;
  transition: transform 2s, opacity 2s;
  transition-timing-function: ease;
  opacity: ${props => {
    switch (props.fadeType) {
      case "in":
        return "1";
      default:
        return "0";
    };
  }};
  transition: ${props => {
    switch (props.fadeType) {
      case "in":
        return `opacity linear 0.25s;`;
      case "out":
        return `opacity linear 0.25s;`;
      default:
        return "";
    }
  }};
`;

export default ModalContainer;
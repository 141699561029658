import React, { useState } from "react";
import styled from "styled-components";
//import { useForm } from "react-hook-form";

const StyledItem = styled.div`
  border-radius: 5px;
  padding: 5px;
  height: auto;
  background: white;
  color: #1c2a35;
  width: 100%;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  margin-bottom: 8px;
  border: 1px solid #d3d3d3;
  line-height: 20px;
  font-size: 15px;
`;

const StyledInput = styled.input`
  border-radius: 5px;
  padding: 5px;
  height: auto;
  background: white;
  color: #000;
  width: 100%;
  box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25);
  margin-bottom: 8px;
  border: 1px solid #d3d3d3;
  placeholder: Add a title for this card;
`;

const EditBackDrop = styled.div`
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  color: #fff;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
`;
const TaskItem = (props) => {
  const [showEdit] = useState(false);
  return (
    <>
      {showEdit === true ? (
        <EditBackDrop>
          <StyledInput></StyledInput>
        </EditBackDrop>
      ) : (
        <StyledItem
          {...props.provided.draggableProps}
          {...props.provided.dragHandleProps}
          ref={props.domRef}
        >
          {props.task.title}
        </StyledItem>
      )}
    </>
  );
};

export default TaskItem;

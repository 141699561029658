import React, { useState } from "react";
import styled from "styled-components";
import TaskItem from "./taskItem";
import NewTaskModal from "./newTask";
import { useDispatch } from "react-redux";
import { RowNoPadding } from "../../theme/styles";
import { Droppable, Draggable } from "react-beautiful-dnd";
import * as types from "../../store/reducers/types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const StyledColumn = styled.div`
  flex-flow: column wrap;
  display: flex;
  width: 272px;
  min-width: 272px;
  background: #ebecf0;
  padding 8px;
  border-radius: 5px;
  margin: 0 10px 0 10px; 
  min-height: 150px;
  height: 100%;
`;

const StyledColumnHeader = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-top: 5px;
  padding-bottom: 2px;
`;
const StyledHr = styled.hr`
  padding-top: 5px;
  padding-bottom: 2px;
  height: 0;
  border-top: 1px solid #1c2932;
`;
const NewTaskButton = styled.button`
  background: #ebecf0;
  padding: 8px;
  border-radius: 5px;
  height: 100%;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
  outline: none;
  transition: 0.3s ease;
  cursor: pointer;
  &:hover {
    color: #2b2e4a;
    -webkit-box-shadow: 1px 0px 7px 0px rgba(50, 50, 50, 0.51);
    -moz-box-shadow:    1px 0px 7px 0px rgba(50, 50, 50, 0.51);
    box-shadow:         1px 0px 7px 0px rgba(50, 50, 50, 0.51);
  }
`;

const IconHolder = styled.span`
  cursor: pointer;
  color: red;
  font-size: 17px;
`;

const Column = (props) => {
  const [ showNewCard, setShowNewCard ] = useState(false)
  const renderTask = () => {
    return props.tasks.map((task, index) => {
      return (
        <Draggable key={task.id} draggableId={task.id} index={index}>
          {(provided) => {
            return (
              <TaskItem
                key={task.id}
                domRef={provided.innerRef}
                provided={provided}
                task={task}
                index={index}
              />
            );
          }}
        </Draggable>
      );
    });
  };
  const tasks = renderTask();
  const dispatch = useDispatch();
  const renderStyled = (provided) => {
    return (
      <StyledColumn {...provided.droppableProps} ref={provided.innerRef}>
        <RowNoPadding>
        <StyledColumnHeader>{props.title}</StyledColumnHeader>
          <IconHolder onClick={() => dispatch({type: types.DELETE_COLUMN, value: props.id, index: props.index})}><FontAwesomeIcon icon={faTrashAlt}/></IconHolder>
        </RowNoPadding>
        <StyledHr></StyledHr>
        {tasks}
        {provided.placeholder}
        <NewTaskButton onClick={() => setShowNewCard(true)}>
          <FontAwesomeIcon icon={faPlus} />
          &nbsp; Create a Card
        </NewTaskButton>
        {showNewCard === true ? <NewTaskModal showModal={showNewCard} columnId={props.id} onClick={() => setShowNewCard(false)} /> : '' }
      </StyledColumn>
    );
  };

  return (
    <Droppable droppableId={props.id}>
      {(provided) => renderStyled(provided)}
    </Droppable>
  );
};

export default Column;

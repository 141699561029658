import React from "react"
import TaskBoard from "../components/taskBoard/taskBoard"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Task = () => (
  <Layout>
    <SEO title="Task Board" />
    <TaskBoard />
  </Layout>
)

export default Task
import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { fadeDown } from "../../theme/animations";
import { RowNoPadding, StyledInput, ErrorSpan } from "../../theme/styles";
import { useDispatch } from "react-redux";
import * as types from "../../store/reducers/types";

const StyledColumn = styled.div`
  flex-flow: column wrap;
  display: flex;
  min-width: 272px;
  background: #ebecf0;
  padding 8px;
  border-radius: 5px;
  margin: 0 10px 0 10px; 
  height: 100%;
  animation: ${fadeDown} 0.3s ease 0s 1 normal forwards;
`;

const SubmitButton = styled.button`
  background: #5aac44;
  box-shadow: none;
  border: none;
  color: #fff;
  padding: 6px 12px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 20px;
  border-radius: 5px;
  outline: none;
`;

const IconHolder = styled.span`
  cursor: pointer;
  color: red;
  font-size: 17px;
`;

const NewColumn = (props) => {
  const { register, handleSubmit, errors, reset  } = useForm();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch({type: types.CREATE_COLUMN, value: data})
    reset({})
    props.cancel()
  };
  return (
    <StyledColumn>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledInput
          name="title"
          type="text"
          placeholder="List Title"
          ref={register({ required: true })}
        />
        {errors.title && <ErrorSpan>Please input a Title</ErrorSpan>}

        <RowNoPadding>
          <SubmitButton type="submit">Submit</SubmitButton>
          <IconHolder onClick={props.cancel}>
          <i className="far fa-trash-alt"></i>
          </IconHolder>
        </RowNoPadding>
      </form>
    </StyledColumn>
  );
};

export default NewColumn;

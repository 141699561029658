import styled from "styled-components";
import media from './media';

export const StyledAnchor = styled.a`
transition: all 0.5s ease;
border-bottom: 2px solid #e84545;
&:visited {
  color: #2B2E4A;
  text-decoration: none;
}

&:link {
  color: #2B2E4A;
  text-decoration: none;
}

&:hover {
  color: #2B2E4A;
  text-decoration: none;
  border-bottom: 2px solid #e84545;
}

`

export const Row = styled.div`
display: flex;
flex-flow: row wrap;
width: 100%;
margin-bottom: 20px;
justify-content: space-between;
`
export const RowNoPadding = styled.div`
display: flex;
flex-flow: row wrap;
width: 100%;
justify-content: space-between;
`

export const Container = styled.div`
padding-right: 50px;
padding-left: 50px;
margin-right: auto;
margin-left: auto;
display: flex;
justify-content: center;
`
export const P20 = styled.div`
padding: 20px;
`
export const P50 = styled.div`
padding: 50px;
`
export const P100 = styled.div`
padding: 100px;
`

export const StyledInput = styled.input`
  border-radius: 5px;
  padding: 5px;
  height: auto;
  background: white;
  color: #000;
  width: 100%;
  font-size: 17px;
  font-weight: 300;
  box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25);
  margin-bottom: 8px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: black;
  }
  :-ms-input-placeholder {
    color: black;
  }
`;

export const StyledTextArea = styled.textarea`
  border-radius: 5px;
  padding: 5px;
  height: auto;
  background: white;
  color: #000;
  width: 100%;
  font-size: 17px;
  font-weight: 300;
  box-shadow: 0 1px 0 rgba(9, 30, 66, 0.25);
  margin-bottom: 8px;
  resize: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: black;
  }
  :-ms-input-placeholder {
    color: black;
  }
`;

export const ErrorSpan = styled.span`
margin-bottom: 8px;
color: #e84545;
`

export const Column6LG = styled.div`
display: flex;
flex-flow: column wrap;
width: 50%;
${media.tablet`width: 100%;`};
`
export const Column6SM = styled.div`
display: flex;
flex-flow: column wrap;
width: 50%;
padding-right: 15px;
padding-left: 15px;
`
export const Column4 = styled.div`
display: flex;
flex-flow: column wrap;
width: 33.333%;
`
export const Column3 = styled.div`
display: flex;
flex-flow: column wrap;
width: 25%;
padding-right: 15px;
padding-left: 15px;
`
export const Column2 = styled.div`
display: flex;
flex-flow: column wrap;
width: 16.6667%;
padding-right: 15px;
padding-left: 15px;
`
export const Column7 = styled.div`
display: flex;
flex-flow: column wrap;
width: 58.3333%;
padding-right: 15px;
padding-left: 15px;
`
export const Column8 = styled.div`
display: flex;
flex-flow: column wrap;
width: 66.6666%;
padding-right: 15px;
padding-left: 15px;
`
export const Column9 = styled.div`
display: flex;
flex-flow: column wrap;
width: 75%;
padding-right: 15px;
padding-left: 15px;
`
export const Column10 = styled.div`
display: flex;
flex-flow: column wrap;
width: 83.3333%;
padding-right: 15px;
padding-left: 15px;
`
export const Column12 = styled.div`
display: flex;
flex-flow: column wrap;
width: 100%;
`
import React, { useState } from "react";
import styled from "styled-components";
import Column from "./column";
import NewTaskModal from "./newTask";
import NewColumn from "./newColumn";
import { DragDropContext } from "react-beautiful-dnd";
import { useSelector, useDispatch } from "react-redux";
import * as types from "../../store/reducers/types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const TaskWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-image: radial-gradient(
    circle,
    #1b262c,
    #1e3650,
    #433f6f,
    #7b3f7e,
    #b53774,
    #c84783,
    #dc5792,
    #f067a2,
    #db8dd7,
    #beaff5,
    #afcbfe,
    #bbe1fa
  );
  min-height: 100vh;
`;
const StyledContainer = styled.div`
  max-width: 1280px;
  margin-left: 10px;
  margin-right: 10px;
`;
const TaskRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  min-height: 100vh;
`;
const P20 = styled.div`
  padding: 20px;
`;
const CreateColumn = styled.button`
  background: #ebecf0;
  padding: 8px;
  border-radius: 5px;
  margin: 0 10px 0 10px;
  height: 100%;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  min-width: 272px;
  cursor: pointer;
`;

//const itemsDummy = Array(4).fill(Math.random().toString(36).substring(7));

const TaskBoard = () => {
  const columns = useSelector((state) => state.task.columns);
  const tasks = useSelector((state) => state.task.tasks);
  const columnOrder = useSelector((state) => state.task.columnOrder);
  const [showNewTaskModal, setTaskModalShow] = useState(false);
  const [showNewColumnInput, setNewColumnInput] = useState(false);
  const dispatch = useDispatch();

  const handleDragEnd = (dropZone) => {
    const { destination, source, draggableId } = dropZone;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    } else {
      if (destination.droppableId === source.droppableId) {
        dispatch({
          type: types.ITEMDRAG,
          value: { index: destination.index, source, draggableId },
        });
      } else {
        dispatch({
          type: types.ITEM_DRAG_NEXT_COL,
          value: { destination, source, draggableId },
        });
      }
    }
  };
  return (
    <>
      <TaskWrapper>
        <P20></P20>
        <StyledContainer>
          <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
            <TaskRow>
              {columnOrder.map((col, index) => {
                const column = columns[col];
                const columnTask = column.taskId.map((id) => tasks[id]);
                return (
                  <Column
                    key={column.id}
                    tasks={columnTask}
                    id={column.id}
                    title={column.title}
                    index={index}
                  ></Column>
                );
              })}
              {showNewColumnInput === false ? (
                <CreateColumn onClick={() => setNewColumnInput(true)}>
                   <FontAwesomeIcon icon={faPlus} />
                  &nbsp; Create A List
                </CreateColumn>
              ) : (
                <NewColumn cancel={() => setNewColumnInput(false)} />
              )}
            </TaskRow>
          </DragDropContext>
        </StyledContainer>
      </TaskWrapper>
      {showNewTaskModal && (
        <NewTaskModal onClick={() => setTaskModalShow(false)} />
      )}
    </>
  );
};

export default TaskBoard;
